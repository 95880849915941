/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'backpack4-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 0a2 2 0 00-2 2H3.5a2 2 0 00-2 2v1a2 2 0 002 2h4v.5a.5.5 0 001 0V7h4a2 2 0 002-2V4a2 2 0 00-2-2H10a2 2 0 00-2-2m1 2a1 1 0 00-2 0zm-4 9v2h6v-2h-1v.5a.5.5 0 01-1 0V11z"/><path pid="1" d="M14 7.599A3 3 0 0112.5 8H9.415a1.5 1.5 0 01-2.83 0H3.5A3 3 0 012 7.599V14a2 2 0 002 2h8a2 2 0 002-2zM4 10.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5z"/>',
    },
});
